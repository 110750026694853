import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Meta from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
    <Meta
      title={frontmatter.title}
      description={frontmatter.description}
      url="https://www.greenvilleappleproducts.com"
    />
    <div className="container pad">
        <div className="prose" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`